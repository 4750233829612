import { memo, FC, ReactNode } from 'react';
import type { TabContent } from '@framework/api/types';
import { TabPanel as ReachPanel } from '@reach/tabs';
import TabComponents from '../TabComponents';
import * as styles from './styles';

type Props = {
  components: TabContent[] | TabContent[][];
  customChild?: ReactNode;
  isInverted?: boolean;
  isPdp?: boolean;
  isPrebuild?: boolean;
};

const TabPanel: FC<Props> = ({
  components,
  customChild,
  isInverted = false,
  isPdp = false,
  isPrebuild = false,
}) => (
  <ReachPanel className={styles.tabPanel(isInverted)}>
    {components ? (
      <TabComponents page={components} isPdp={isPdp} isPrebuild={isPrebuild} />
    ) : (
      customChild
    )}
  </ReachPanel>
);

export default memo(TabPanel);

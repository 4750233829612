import { classnames } from '@tailwindcss-classnames';

// outer
export const specsSection = classnames(
  'columns-1',
  'md:columns-2',
  'md:gap-x-12'
);

export const specsWrapper = classnames('block', 'break-inside-avoid');

// inner
export const speclistItem = classnames(
  'md:mt-0',
  'lg:flex',
  'lg:space-x-2',
  'w-full',
  'pb-3',
  'last:pb-0',
  'dark:text-white'
);

export const heading = classnames('text-4xl', 'font-bold', 'mb-6', 'md:mb-12');

export const specValueLabel = classnames(
  'font-bold',
  'text-left',
  'hyphens-auto',
  'mr-1'
);

export const specCategory = classnames(
  'font-bold',
  'text-nzxt-light-grey-600',
  'dark:text-nzxt-dark-grey-200',
  'dark:border-nzxt-dark-grey-200',
  'uppercase',
  'col-span-12',
  'mb-3',
  'pb-3',
  'border-b'
);
export const sectionHeading = classnames('pb-3', 'md:pb-6', 'mb-3', 'md:mb-6');

export const listWrapper = classnames('mb-6', 'lg:mb-8');

/* eslint-disable no-underscore-dangle */
import { FC, Fragment, memo } from 'react';
import type { TabContent } from '@framework/api/types';
import ContentWrapper from '@components/ContentWrapper';
import InTheBox from '@components/PDP/InTheBox';
import ProductSpecs from '@components/PDP/ProductSpecs';
import FAQSection from '@components/FAQSection/FAQS';
import Testimonials from '@components/Testimonials';
import VideoBlock from '@components/VideoBlock';
import MediaObject from '@components/MediaObject';
import Downloads from '@components/PDP/Downloads';
import ProductDescription from '@components/PDP/ProductDescription';
import ContentGrid from '@components/ContentGrid';
import YotpoWidget from '@components/YotpoReviews/YotpoWidget';
import SectionWrapper from '@components/SectionWrapper';
import VideoCarousel from '@components/VideoCarousel';
import PrebuildTabDisclaimer from './PrebuildTabDisclaimer';
import cssStyle from './TabComponents.module.css';

type Props = {
  page: TabContent[] | TabContent[][];
  isPdp?: boolean;
  isPrebuild?: boolean;
};

const TabComponents: FC<Props> = ({
  page,
  isPdp = false,
  isPrebuild = false,
}) =>
  isPdp ? (
    <div className={cssStyle.wrapper}>
      {Array.isArray(page) &&
        page?.length > 0 &&
        page?.map(
          s =>
            Array.isArray(s) &&
            s?.length > 0 &&
            s?.map(
              p =>
                p?.id && (
                  <Fragment key={p.id}>
                    {(() => {
                      switch (p._modelApiKey) {
                        case 'techSpec':
                          return (
                            <SectionWrapper noSpacing>
                              <ContentWrapper>
                                {isPrebuild ? <PrebuildTabDisclaimer /> : null}
                                <ProductSpecs specsData={p.specs} />
                              </ContentWrapper>
                            </SectionWrapper>
                          );

                        case 'in_the_box':
                          return (
                            <SectionWrapper noSpacing>
                              <ContentWrapper>
                                <InTheBox data={p.inTheBox} />
                              </ContentWrapper>
                            </SectionWrapper>
                          );

                        case 'description':
                          return (
                            <SectionWrapper noSpacing>
                              <ContentWrapper>
                                <ProductDescription
                                  overview={p.description}
                                  overviewMore={p.descriptionExtra}
                                />
                              </ContentWrapper>

                              {p?.inTheBox && <InTheBox data={p.inTheBox} />}
                            </SectionWrapper>
                          );

                        // Grid
                        case 'tabs_grid':
                          return <ContentGrid {...p} noWrapperSpacing />;

                        // Testimonial Collection
                        case 'testimonal_collection':
                          return (
                            <Testimonials
                              noWrapperSpacing
                              testimonialsData={p?.testimonials}
                            />
                          );

                        // FAQ Section
                        case 'faq_section':
                          return (
                            <SectionWrapper noSpacing>
                              <ContentWrapper>
                                <FAQSection faqQuestions={p?.faqs} />
                              </ContentWrapper>
                            </SectionWrapper>
                          );
                        // Downloads
                        case 'downloads':
                          return <Downloads downloadsData={p?.downloads} />;

                        case 'video_carousel':
                          return <VideoCarousel {...p} isCompact />;

                        // Video Block
                        case 'video_block':
                          return (
                            <SectionWrapper noSpacing>
                              <ContentWrapper>
                                <VideoBlock
                                  heading={p?.heading}
                                  eyebrow={p?.eyebrow}
                                  subheading={p?.subheading}
                                  video={p?.video}
                                  videoPoster={p?.videoPoster}
                                  buttonLabel={p?.buttonLabel}
                                  buttonLink={p?.internalButtonLink}
                                  darkText={p?.darkText}
                                  noWrapperSpacing
                                  rounded
                                />
                              </ContentWrapper>
                            </SectionWrapper>
                          );
                        case 'reviews':
                          return (
                            <SectionWrapper>
                              <ContentWrapper>
                                <YotpoWidget
                                  type="reviews"
                                  productName={p?.productName}
                                  id={p?.parentId}
                                  slug={p?.slug}
                                  description={p?.description}
                                  image={p?.image}
                                />
                              </ContentWrapper>
                            </SectionWrapper>
                          );

                        default:
                          return null;
                      }
                    })()}
                  </Fragment>
                )
            )
        )}
    </div>
  ) : (
    <div className={cssStyle.wrapper}>
      {page?.map(
        s =>
          s.id && (
            <Fragment key={s.id}>
              {(() => {
                switch (s._modelApiKey) {
                  // Testimonial Collection
                  case 'testimonal_collection':
                    return (
                      <Testimonials
                        noWrapperSpacing
                        testimonialsData={s?.testimonials}
                      />
                    );

                  case 'video_carousel':
                    return <VideoCarousel {...s} isCompact />;

                  // Video Block
                  case 'video_block':
                    return (
                      <SectionWrapper noSpacing>
                        <ContentWrapper>
                          <VideoBlock
                            heading={s?.heading}
                            eyebrow={s?.eyebrow}
                            subheading={s?.subheading}
                            video={s?.video}
                            videoPoster={s?.videoPoster}
                            buttonLabel={s?.buttonLabel}
                            buttonLink={s?.internalButtonLink}
                            darkText={s?.darkText}
                            noWrapperSpacing
                          />
                        </ContentWrapper>
                      </SectionWrapper>
                    );

                  // FAQ Section
                  case 'faq_section':
                    return (
                      <SectionWrapper noSpacing>
                        <ContentWrapper>
                          <FAQSection faqQuestions={s?.faqs} />
                        </ContentWrapper>
                      </SectionWrapper>
                    );

                  // Media Object Section
                  case 'media_object_section':
                    return (
                      <MediaObject noWrapper mediaObjects={s?.mediaObjects} />
                    );

                  // Grid
                  case 'tabs_grid':
                    return <ContentGrid {...s} noWrapperSpacing />;
                  default:
                    return null;
                }
              })()}
            </Fragment>
          )
      )}
    </div>
  );

export default memo(TabComponents);

import { FC } from 'react';
import { IconInfo } from '@nzxt/react-icons';
import { useTranslation as t } from '@utils/hooks';
import * as styles from './styles';

const PrebuildTabDisclaimer: FC = () => {
  // TODO PDP - final verbiage and translations
  const TEXT = t('prebuild_specs_disclaimer');

  // TODO PDP - styling
  return (
    <div className={styles.prebuildDisclaimer}>
      <IconInfo className={styles.icon} />
      {TEXT}
    </div>
  );
};

export default PrebuildTabDisclaimer;

import { classnames } from '@tailwindcss-classnames';

export const sectionWrapper = classnames('pb-6', 'md:pb-10');

const withFps = classnames('col-span-12', 'lg:col-span-7');
const sansFps = classnames('col-span-12', 'lg:col-span-10');
export const descriptionLayout = classnames(
  'grid-cols-12',
  'grid',
  'gap-y-2',
  'lg:gap-y-0',
  'lg:gap-x-4'
);

export const descriptionColumn = (hasFps: boolean): string =>
  classnames({
    [withFps]: hasFps,
    [sansFps]: !hasFps,
  });

export const fpsColumn = classnames(
  'col-span-12',
  'lg:col-span-5',
  'bg-nzxt-light-grey-50',
  'rounded-md',
  'px-4',
  'py-6'
);

export const prebuildDisclaimer = classnames('flex', 'mb-6', 'dark:text-white');
export const icon = classnames('w-6', 'h-6', 'mr-2');

export default sectionWrapper;

import { FC, memo, useState, useEffect, useMemo, useRef } from 'react';
import Router from 'next/router';
import debounce from 'lodash.debounce';
import GTM from '@utils/gtm';
import { Tabs, TabList, TabPanels, TabsOrientation } from '@reach/tabs';
import ContentWrapper from '@components/ContentWrapper';
import SectionWrapper from '@components/SectionWrapper';
import TabLabel from '../TabLabel';
import TabPanel from '../TabPanel';
import * as styles from './styles';

type Props = {
  locale?: string;
  defaultTab?: number;
  isPrebuild?: boolean;
  tabs:
    | any
    | {
        tabPanel: any;
        label: string;
        id?: string;
        pdpOverride?: boolean;
      }[];
};

const TabGroup: FC<Props> = ({ tabs, defaultTab, isPrebuild, locale }) => {
  const hasTabLabels = useMemo(
    () => Array.isArray(tabs) && tabs?.length > 0,
    [tabs]
  );
  const filteredTabs = useMemo(() => tabs?.filter(tab => tab?.label), [tabs]);

  const [tabIndex, setTabIndex] = useState(0);
  const debouncedReset = debounce(() => setTabIndex(0), 250);
  const tabContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultTab) {
      setTabIndex(defaultTab);
    }
  }, [defaultTab]);

  useEffect(() => {
    const scrollToLeft = (): void => {
      const elmntToView = tabContainerRef?.current;
      if (elmntToView) {
        elmntToView?.scrollTo({ left: 0, behavior: 'smooth' });
      }
    };

    Router.events.on('routeChangeComplete', debouncedReset);
    Router.events.on('routeChangeComplete', scrollToLeft);

    return () => {
      Router.events.off('routeChangeComplete', debouncedReset);
    };
  }, [debouncedReset]);

  const handleTabsChange = (index: number): void => {
    setTabIndex(index);
    GTM.dataLayer({
      dataLayer: {
        event: 'tabClick',
      },
    });
  };

  return hasTabLabels ? (
    <SectionWrapper>
      <Tabs
        defaultIndex={defaultTab || 0}
        index={tabIndex}
        onChange={handleTabsChange}
        key={filteredTabs.length}
        orientation={TabsOrientation.Horizontal}
      >
        {({ selectedIndex }) => {
          const isActive = (index: number): boolean => selectedIndex === index;

          return (
            <>
              <ContentWrapper>
                <TabList className={styles.tabList} ref={tabContainerRef}>
                  {filteredTabs?.map((tab, i) => (
                    <TabLabel key={tab?.id} id={tab?.id} isActive={isActive(i)}>
                      {tab?.label}
                    </TabLabel>
                  ))}
                </TabList>
              </ContentWrapper>

              <TabPanels key={`${locale}-${filteredTabs.length}`}>
                {filteredTabs?.map(tab => (
                  <TabPanel
                    key={tab?.label}
                    isPdp={tab?.pdpOverride}
                    components={tab?.tabPanel}
                    isPrebuild={isPrebuild}
                  />
                ))}
              </TabPanels>
            </>
          );
        }}
      </Tabs>
    </SectionWrapper>
  ) : null;
};

export default memo(TabGroup);

import { classnames } from '@tailwindcss-classnames';

export const heading = classnames('h4', 'mb-6');

export const buttonWrapper = classnames('flex', 'justify-center', 'mt-6');
export const contactButton = classnames('w-56');

const invertedTabs = classnames('text-white');
const defaultTabs = classnames('text-nzxt-light-grey-900');
export const tabPanel = (isInverted: boolean): string =>
  classnames({ [invertedTabs]: isInverted }, { [defaultTabs]: !isInverted });

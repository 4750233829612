import { classnames } from '@tailwindcss-classnames';

export const flexCenter = classnames('flex', 'items-center');

export const relative = classnames('relative');
export const borderInitial = classnames(
  'absolute',
  'inset-0',
  flexCenter,
  'dark:hidden'
);

const darkBorder = classnames('border-nzxt-light-grey-600');
const defaultBorderColor = classnames('border-gray-300');
export const borderSecondary = (isInverted: boolean): string =>
  classnames('w-full', 'border-t', {
    [defaultBorderColor]: !isInverted,
    [darkBorder]: isInverted,
  });

export const copyWrapper = classnames(
  'relative',
  'flex',
  'justify-center',
  'dark:border-b-2',
  'dark:border-b-nzxt-dark-grey-100',
  'dark:justify-start',
  'dark:border-nzxt-dark-grey-300'
);

const headingStyle = classnames('text-lg', 'font-semibold');
const defaultStyle = classnames('font-semibold');
const darkStyle = classnames('text-nzxt-light-grey-700');
const defaultColor = classnames(
  'text-nzxt-light-grey-600',
  'dark:text-nzxt-dark-grey-100'
);
const smallSize = classnames('text-sm');
export const copy = (
  isHeading: boolean,
  isSmall: boolean,
  isInverted: boolean
): string =>
  classnames(
    'bg-white',
    'dark:bg-transparent',
    'px-2',
    'dark:px-0',
    'dark:pb-2',
    {
      [headingStyle]: isHeading,
      [smallSize]: !isHeading && isSmall,
      [defaultStyle]: !isHeading && !isSmall,
      [darkStyle]: isInverted,
      [defaultColor]: !isInverted,
    }
  );

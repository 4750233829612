import { FC, memo } from 'react';
import clsx from 'clsx';
import * as styles from './styles';

type Props = {
  text: string;
  headingStyle?: boolean;
  isInverted?: boolean;
  className?: string;
  isSmall?: boolean;
};

const TextConjunction: FC<Props> = ({
  text,
  headingStyle = false,
  isInverted = false,
  isSmall = false,
  className = '',
}) => (
  <div className={clsx(styles.relative, className)}>
    <div className={styles.borderInitial}>
      <div className={styles.borderSecondary(isInverted)} />
    </div>
    <div className={styles.copyWrapper}>
      <span className={styles.copy(headingStyle, isSmall, isInverted)}>
        {text}
      </span>
    </div>
  </div>
);

export default memo(TextConjunction);

import { FC, memo } from 'react';
import { useRouter } from 'next/router';
import type { DatoProductSpecs } from '@framework/api/types';
import { useMedia } from '@utils/hooks';
import parseLocale from '@utils/parse-locale';
import * as styles from './styles';

type Props = {
  specsData: DatoProductSpecs[];
};

const ProductSpecs: FC<Props> = ({ specsData }) => {
  const { locale, defaultLocale } = useRouter();
  const parsedLocale = parseLocale(defaultLocale, locale);
  const lang = parsedLocale[0] === 'en' ? 'en-us' : parsedLocale[0];
  const isMd = useMedia('md');
  const isSm = useMedia('sm');
  const isLg = !isMd && !isSm;

  return (
    <div className={styles.specsSection}>
      {specsData.map(
        (item, i) =>
          item?.specValues && (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${item.specCategory}-${i}`}
              className={styles.specsWrapper}
            >
              <p className={styles.specCategory}>{item.specCategory}</p>
              <ul className={styles.listWrapper}>
                {Object?.keys(item?.specValues).length > 0 &&
                  Object?.keys(item?.specValues).map((detail, index) => (
                    <li
                      className={styles.speclistItem}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${detail}-${index}`}
                    >
                      {isLg ? (
                        <p>
                          <span lang={lang} className={styles.specValueLabel}>
                            {detail}:
                          </span>
                          {item.specValues[detail]}
                        </p>
                      ) : (
                        <>
                          <p lang={lang} className={styles.specValueLabel}>
                            {detail}:
                          </p>
                          <p>{item.specValues[detail]}</p>
                        </>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          )
      )}
    </div>
  );
};

export default memo(ProductSpecs);

import { classnames } from '@tailwindcss-classnames';

export const detailsHeading = classnames(
  'uppercase',
  'text-nzxt-dark-grey-500'
);

export const divider = classnames('col-span-12', 'md:col-span-9', 'max-w-3xl');

export const insideTheBox = classnames(
  'flex',
  'w-full',
  'py-2',
  'border-b',
  'dark:border-nzxt-dark-grey-200',
  'dark:last:border-b-transparent',
  'last:border-b-transparent'
);
export const sectionHeading = classnames(
  'dark:text-white',
  'mb-4',
  'col-span-12',
  'md:col-span-3',
  'text-xl',
  'font-semibold'
);
export const copy = classnames(
  'dark:text-white',
  'flex',
  'w-full',
  'justify-between',
  'space-x-2'
);
export const background = classnames(
  'grid-cols-12',
  'grid',
  'gap-y-2',
  'lg:gap-y-0',
  'md:gap-x-8',
  'border-t',
  'dark:border-nzxt-dark-grey-200',
  'pt-8'
);

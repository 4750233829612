import { FC, memo } from 'react';
import cn from 'clsx';
import { useTranslation as t } from '@utils/hooks';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import HydrateMdx from '@components/HydrateMdx';
import extendedStyles from './pdp.module.css';
import * as styles from './styles';

type Props = {
  overview: MDXRemoteSerializeResult;
  overviewMore?: MDXRemoteSerializeResult;
};

const ProductDescription: FC<Props> = ({ overview, overviewMore }) => {
  const DETAILS_LABEL = t('details');

  return (
    <div className={styles.descriptionLayout}>
      <h2 className={styles.heading}>{DETAILS_LABEL}</h2>
      <div className={cn(styles.overview, extendedStyles.list)}>
        {overview && <HydrateMdx mdx={overview} />}
        {overviewMore && <HydrateMdx mdx={overviewMore} />}
      </div>
    </div>
  );
};

export default memo(ProductDescription);

import { memo, FC, ReactNode } from 'react';
import { Tab } from '@reach/tabs';

import * as styles from './styles';

type Props = {
  children: ReactNode | string;
  isActive?: boolean;
  id?: string;
};

const TabLabel: FC<Props> = ({ children, isActive, id }) => (
  <Tab className={styles.tabLabel(isActive)}>
    {id ? <span id={id}>{children}</span> : children}
  </Tab>
);

export default memo(TabLabel);

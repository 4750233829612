import { FC, memo } from 'react';
import { useTranslation as t } from '@utils/hooks';
import type { DatoInTheBox } from '@framework/api/types';
import * as styles from './styles';

type Props = {
  data: DatoInTheBox;
};

const InTheBox: FC<Props> = ({ data }) => {
  const IN_THE_BOX_LABEL = t('title_in_the_box');

  return data ? (
    <div className={styles.background}>
      <h2 className={styles.sectionHeading}>{IN_THE_BOX_LABEL}</h2>

      <ul className={styles.divider}>
        {Object?.keys(data).map(item => (
          <li className={styles.insideTheBox} key={item}>
            <p className={styles.copy}>
              {item}
              <span>{data[item]}</span>
            </p>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default memo(InTheBox);

import { classnames } from '@tailwindcss-classnames';

export const overview = classnames(
  'space-y-2',
  'text-nzxt-light-grey-800',
  'dark:text-white',
  'col-span-12',
  'md:col-span-9',
  'max-w-3xl'
);

export const overviewMore = classnames(
  'space-y-2',
  'pb-8',
  'text-sm',
  'text-nzxt-light-grey-800'
);

export const discloseButton = classnames(
  'pb-2',
  'pt-3',
  'text-blue-600',
  'font-semibold',
  'focus:outline-none',
  'hover:underline'
);

export const descriptionLayout = classnames(
  'grid-cols-12',
  'grid',
  'gap-y-2',
  'lg:gap-y-0',
  'md:gap-x-8'
);

export const heading = classnames(
  'dark:text-white',
  'col-span-12',
  'md:col-span-3',
  'text-xl',
  'font-semibold',
  'mb-4',
  'lg:mb-0'
);

import { classnames } from '@tailwindcss-classnames';

const activeText = classnames(
  'border-b',
  'border-b-4',
  'pb-2',
  'border-nzxt-volt-400',
  'dark:border-white'
);

const inactiveText = classnames(
  'text-nzxt-dark-grey-400',
  'hover:text-nzxt-dark-grey-800',
  'dark:hover:text-nzxt-light-grey-200',
  'dark:text-nzxt-dark-grey-200'
);

export const tabLabel = (isActive: boolean): string =>
  classnames(
    'mr-6',
    'ml-6',
    'md:mr-8',
    'md:ml-8',
    'first:ml-0',
    'md:text-lg',
    'font-semibold',
    'relative',
    'whitespace-nowrap',
    'focus:outline-none',
    'focus:border-b',
    'focus:border-b-4',
    'focus:pb-2',
    'focus:border-nzxt-volt-400',
    'dark:focus:border-white',
    { [activeText]: isActive },
    { [inactiveText]: !isActive }
  );

export default tabLabel;

import { classnames } from '@tailwindcss-classnames';

export const tabList = classnames(
  'border-b',
  'dark:border-nzxt-dark-grey-200',
  'mb-8',
  'lg:mb-12',
  'w-full',
  'overflow-x-auto',
  'md:overflow-auto',
  'flex',
  'items-start'
);
export const selectWrapper = classnames(
  'lg:hidden',
  'block',
  'border-b',
  'pb-3',
  'mb-3'
);
